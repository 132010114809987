import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  './../components/errorHandler'

/* Service to get Client data
@params
    * none
@headers
    * token: string
*/
export const getClientData = async (userId) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/customers/V1/getCustomerDetails/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }    
}

/* Service to get Client Vehicles
@params
    * userId: string
@headers
    * token: string
*/
export const getClientVehicles = async (userId) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/customers/V1/getCustomerVehicles/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}

/* Service to get Aprove Client data
@params
    * userId: string
    * name: string
    * middleName: string
    * lastName: string (opcional)
    * birthDate: string
    * curp: string
@headers
    * token: string
*/
export const aproveClientData = async (data, userId) => {
    try {
        let body = {
            name: data.name,
            middleName: data.middleName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            curp: data.curp
        }

        const response = await fetch(`${Constants.API.BASE_URL}/customers/V1/validateCustomer/${userId}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}