import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  './../components/errorHandler'

/* Service to Notificate to user about error with your documents
@params
    * userId: string
*/
export const NotificateUserError = async (userId) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/notifications/V1/userError/${userId}`, {
            method: 'POST',
            body: [],
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}

/* Service to Notificate to user about error with your vehicle
@params
    * userId: string
    * vehicleId: string
    * type: string
*/
export const NotificateVehicleError = async (userId, vehicleId, type) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/notifications/V1/vehicleError/${userId}/${vehicleId}/${type}`, {
            method: 'POST',
            body: [],
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}

/* Service to Notificate to user about error general with your vehicle
@params
    * userId: string
    * vehicleId: string
    * message: string
*/
export const NotificateVehicleErrorGeneral = async (userId, vehicleId, message) => {
    try {
        const body = {
            message: message
        }

        const response = await fetch(`${Constants.API.BASE_URL}/notifications/V1/vehicleErrorGeneral/${userId}/${vehicleId}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}