// Import react components
import { useEffect, useState } from 'react'

// Material UI components
import {
    Container,
    Grid,
    Typography,
    TextField,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Stack,
    IconButton,
    Avatar
} from '@mui/material'

// Import router dom components
import { useNavigate, useParams } from "react-router-dom"

// Import components
import Footer from './../components/footer'
import NavBar from './../components/navBar/navBar'
import Modal from './../components/modals/modalClient'
import ModalErrorUser from './../components/modals/modalErrorUser'

// Import assets
import IconAnalyze from './../assets/icons/Icon_Analyze_01.png'
import IconView from './../assets/icons/Icon_View_01.png'
import IconDocument from './../assets/icons/Icon_Document_01.png'
import IconNotify from './../assets/icons/Icon_Notify_01.png'

// Import Services
import * as ClientServices from './../api/clients'
import * as NotificationServices from './../api/notifications'

import moment from 'moment'
import 'moment/locale/es'
moment.suppressDeprecationWarnings = true
moment.locale('es-mx')


const Client = () => { 
    let navigate = useNavigate()
    const { idUser } = useParams()

    /* Function to open modal */
    const [open, setOpen] = useState(false)
    const handleOpenModal = async () => {
        if(
            user.name !== '' &&
            user.middleName !== '' &&
            user.birthDate !== '' &&
            user.curp !== ''
        ) {
            setOpen(true)            
        } else {
            setActiveValidations(true)
        }
    }
    const handleCloseModal = async () => {
        setOpen(!open)
    }
    const handleCloseModalAcept = async () => {
        const response = await ClientServices.aproveClientData(user, user.idClient)

        if(response.success) {
            getDataClient()
            setOpen(!open)
        } else {
            // alert indicando error
        }
    }

    /* Function to open modal error */
    const [openError, setOpenError] = useState(false)
    const [dataModalError, setDataModalError] = useState({
        title: '',
        message: ''
    })
    const handleOpenModalError = async () => {
        setOpenError(true)
        setDataModalError({
            title: '',
            message: 'Notificar al cliente de una\ninconsistencia con sus datos'
        })
    }
    const handleCloseModalError = async () => {
        setOpenError(!openError)
        setDataModalError({
            title: '',
            message: ''
        })
    }
    const handleCloseModalErrorAcept = async () => {
        const response = await NotificationServices.NotificateUserError(user.idClient)

        if(response.success) {
            setOpenError(!openError)
            setDataModalError({
                title: '',
                message: ''
            })
        } else {
            // alert indicando error
        }
    }

    /* Function of data of user */
    const [user, setUser] = useState({
        idClient: '',
        walletId: '',
        name: '',
        middleName: '',
        lastName: '',
        birthDate: '',
        curp: '',
        status: '',
        avatarPicture: '',
        attempts: 0
    })
    const [activeValidations, setActiveValidations] = useState(false)
    const [documents, setDocuments] = useState([])
    

    /* Function of table */
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    /* Function go to */
    const handleGoTo = async (to) => {
        navigate(to)
    }

    /* Function to get data of user */
    const getDataClient = async () => {
        const response = await ClientServices.getClientData(idUser)
        //console.log(response)

        if(response.success) {
            const data = response.data

            let URLPicture = ''
            for(var i in data.UsersDocuments) {
                const doc = data.UsersDocuments[i]
                
                if(doc.DocumentTypes?.Description === 'Selfie') {
                    URLPicture = doc.URL
                }
            }

            const user = {
                idClient: data.UserId,
                name: data.Name ? data.Name : '',
                middleName: data.MiddleName ? data.MiddleName : '',
                lastName: data.LastName ? data.LastName : '',
                birthDate: data.BirthDate ? moment(data.BirthDate).format('DD / MM / YYYY') : '',
                curp: data.CURP ? data.CURP : '',
                status: data.UserStatus?.Description,
                avatarPicture: URLPicture.length !== 0 ? URLPicture : '',
                attempts: data.attempts
            }
            setUser(user)

            var documents = []
            for(var i in data.UsersDocuments) {
                const document = data.UsersDocuments[i]

                documents.push({
                    idDocument: document.DocumentId,
                    documentType: document.DocumentTypes?.Description,
                    documentUrl: document.URL
                })
            }
            setDocuments(documents)
        } else {
            // alert de error
        }
    }

    useEffect(() => {
        const getRowsTable = async () => {
            const response = await ClientServices.getClientVehicles(idUser)
            //console.log(response)

            if(response.success) {
                const data = response.data

                setRows(data)
            } else {
                // aqui va el alert
            }

        }
    
        getDataClient()
        getRowsTable()

        //console.log(idUser)
    }, [])

    return (
        <Container style={{ padding: 0 }}>
            <NavBar routes={'Solicitudes/Cliente'} params={{}} />

            <Container style={{ minHeight: 'calc(100vh - 92px)',  paddingTop: 'calc(50px + 98px)' }}>
                <Container style={{ padding: 0, marginTop: '25px' }}>
                    <Grid
                        container
                        columnSpacing={4}
                    >
                        {/* Avatar and data client */}
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={7}
                        >
                            {/* Avatar */}
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                                sm={3}
                                md={3}
                            >
                                {
                                    user.avatarPicture.length !== 0 
                                    ? <Avatar className='avatarClient' alt="client Name" src={ user.avatarPicture } /> 
                                    : <Avatar className='avatarClient'>{`${user.name.charAt(0).toLocaleUpperCase()}${user.middleName.charAt(0).toLocaleUpperCase()}`}</Avatar>
                                }
                            </Grid>

                            {/* Client data */}
                            <Grid
                                container
                                item
                                columnSpacing={1}
                                xs={12}
                                sm={9}
                                md={9}
                            >
                                {/* Id Client */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Id Cliente: 
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    <Typography
                                        variant='body1'
                                        className='orangeWord'
                                    >
                                        { user.idClient }
                                    </Typography>
                                </Grid>

                                {/* Wallet Id */}
                                {/* <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Wallet Id: 
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    <Typography
                                        variant='body1'
                                        className='orangeWord'
                                    >
                                        { user.walletId }
                                    </Typography>
                                </Grid> */}

                                {/* Name */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Nombre{user.name.length === 0 && user.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    {user.name.length === 0 || user.status.toLocaleLowerCase() === 'pending'
                                        ?   <TextField 
                                                fullWidth
                                                className='inputStyle'
                                                variant="standard" 
                                                value={ user.name }
                                                onChange={(e) => {
                                                    setUser({
                                                        ...user,
                                                        name: e.target.value
                                                    })
                                                }}
                                                error={ user?.name.length === 0 && activeValidations }
                                                helperText={user?.name.length === 0 && activeValidations ? "El nombre es obligatorio" : null}
                                            />
                                        :   <Typography
                                                variant='body1'
                                                className='orangeWord'
                                            >
                                                { user.name }
                                            </Typography>
                                    }
                                </Grid>

                                {/* Middle Name */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Apellido Paterno{user.middleName.length === 0 && user.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    {user.middleName.length === 0 || user.status.toLocaleLowerCase() === 'pending'
                                        ?   <TextField 
                                                fullWidth
                                                className='inputStyle'
                                                variant="standard" 
                                                value={ user.middleName }
                                                onChange={(e) => {
                                                    setUser({
                                                        ...user,
                                                        middleName: e.target.value
                                                    })
                                                }}
                                                error={ user?.middleName.length === 0 && activeValidations }
                                                helperText={user?.middleName.length === 0 && activeValidations ? "El apellido paterno es obligatorio" : null}
                                            />
                                        :   <Typography
                                                variant='body1'
                                                className='orangeWord'
                                            >
                                                { user.middleName }
                                            </Typography>
                                    }
                                </Grid>

                                {/* Last Name */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Apellido Materno:
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    {(user.lastName.length === 0 || user.status.toLocaleLowerCase() === 'pending') && user.status.toLocaleLowerCase() === 'pending'
                                        ?   <TextField 
                                                fullWidth
                                                className='inputStyle'
                                                variant="standard" 
                                                value={ user.lastName }
                                                onChange={(e) => {
                                                    setUser({
                                                        ...user,
                                                        lastName: e.target.value
                                                    })
                                                }}
                                            />
                                        :   <Typography
                                                variant='body1'
                                                className='orangeWord'
                                            >
                                                { user.lastName }
                                            </Typography>
                                    }
                                </Grid>

                                {/* Birthday Date */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Fecha de Nacimiento{user.birthDate.length === 0 && user.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    {user.birthDate.length === 0 || user.status.toLocaleLowerCase() === 'pending'
                                        ?   <TextField 
                                                fullWidth
                                                className='inputStyle'
                                                variant="standard" 
                                                type='date'
                                                value={ user.birthDate }
                                                onChange={(e) => {
                                                    setUser({
                                                        ...user,
                                                        birthDate: e.target.value
                                                    })
                                                }}
                                                error={ user?.birthDate.length === 0 && activeValidations }
                                                helperText={user?.birthDate.length === 0 && activeValidations ? "La fecha de nacimiento es obligatoria" : null}
                                            />
                                        :   <Typography
                                                variant='body1'
                                                className='orangeWord'
                                            >
                                                { user.birthDate }
                                            </Typography>
                                    }
                                </Grid>

                                {/* CURP */}
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        CURP{user.curp.length === 0 && user.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                    </Typography>
                                </Grid>
                                <Grid
                                    container   
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={7}
                                >
                                    {user.curp.length === 0 || user.status.toLocaleLowerCase() === 'pending'
                                        ?   <TextField 
                                                fullWidth
                                                className='inputStyle'
                                                variant="standard" 
                                                value={ user.curp }
                                                onChange={(e) => {
                                                    setUser({
                                                        ...user,
                                                        curp: e.target.value
                                                    })
                                                }}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                error={ user?.curp.length === 0 && activeValidations }
                                                helperText={user?.curp.length === 0 && activeValidations ? "La CURP es obligatoria" : null}
                                            />
                                        :   <Typography
                                                variant='body1'
                                                className='orangeWord'
                                            >
                                                { user.curp }
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Documents and button to aprove */}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='h6'
                                    className='orangeWord'
                                >
                                    Documentos del Cliente
                                </Typography>
                            </Grid>

                            {/* Documents */}
                            <Grid
                                item
                                xs={12}
                            >
                                {
                                    documents.length !== 0 
                                        ?   <Paper 
                                                elevation={0} 
                                                className='cardDocuments'
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    {
                                                        documents.map((document, index) => {
                                                            return (
                                                                <IconButton
                                                                    key={ index }
                                                                    onClick={() => {
                                                                        window.open(`${document.documentUrl}`, "_blank")
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="Document Icon"
                                                                        src={ IconDocument }
                                                                        width={32}
                                                                        height={32}
                                                                    />
                                                                    { document.documentType }
                                                                </IconButton>
                                                            )
                                                        })
                                                    }
                                                        
                                                </Stack>
                                            </Paper>
                                        :   <Typography
                                                variant='subtitle1'
                                            >
                                                No se cargaron documentos
                                            </Typography>
                                }
                            </Grid>

                            {/* registration attempts and button to aprove user */}
                            <Grid
                                container
                                item
                                xs={12}
                            >
                                {/* registration attempts */}
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    xs={6}
                                >
                                    <Typography
                                        variant='body1'
                                    >
                                        Intentos de registro: { user.attempts }
                                    </Typography>
                                </Grid>

                                {/* Button to aprove user */}
                                {user.status.toLocaleLowerCase() === 'pending' && <Grid
                                    container
                                    item
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    xs={6}
                                >
                                    <Button 
                                        size="small"
                                        variant='contained'
                                        style={{ backgroundColor: '#000000', marginTop: '15px' }}
                                        onClick={handleOpenModal}
                                    >
                                        Aprobar usuario
                                    </Button>
                                </Grid>}
                            </Grid>

                            {/* Button to aprove send error to user */}
                            {user.status.toLocaleLowerCase() === 'pending' && user.status.toLocaleLowerCase() !== 'notified' && <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                xs={12}
                            >
                                <Button 
                                    size="small"
                                    variant='contained'
                                    style={{ backgroundColor: '#000000', marginTop: '15px' }}
                                    onClick={handleOpenModalError}
                                >
                                    Error
                                </Button>
                            </Grid>}
                        </Grid>

                    </Grid>

                </Container>

                {
                    rows.length !== 0  
                        ? <Container style={{ padding: 0, marginTop: '25px' }}>
                            <Typography
                                variant='h5'
                                className='orangeWord'
                                style={{ marginBottom: '25px' }}
                            >
                                Vehículos del Cliente
                            </Typography>

                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='headTableRow'>
                                            <TableCell align={'center'} >
                                                Vehicle Id
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Marca
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Model
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Year
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Serial Number
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='bodyTable'>
                                        <TableRow className='separatorRow'>
                                            <TableCell colSpan={6}></TableCell>
                                        </TableRow>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                                <TableRow role="checkbox" tabIndex={ -1 } key={ index } className='bodyTableRows'>
                                                    <TableCell align={'center'}>
                                                        { row.VehicleId }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.Brand ? row.Brand : 'NA' }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.Model ? row.Model : 'NA' }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.Year ? row.Year : 'NA' }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.SerialNumber ? row.SerialNumber : 'NA' }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                    <Stack 
                                                        direction="row" 
                                                        justifyContent="center"
                                                        alignItems="center" 
                                                        spacing={3} 
                                                        className='buttonActions'
                                                    >
                                                        {row.VehicleStatus?.Description.toLocaleLowerCase() !== 'pending' && <IconButton
                                                            onClick={() => handleGoTo(`/solicitudes/cliente/vehiculos/${row.VehicleId}`)}
                                                        >
                                                            <img
                                                                alt="View Icon"
                                                                src={ IconView }
                                                            />
                                                        </IconButton>}
                                                        {row.VehicleStatus?.Description.toLocaleLowerCase() === 'pending' && <IconButton
                                                            onClick={() => handleGoTo(`/solicitudes/cliente/vehiculos/${row.VehicleId}`)}
                                                        >
                                                            <img
                                                                alt="Analyze Icon"
                                                                src={ IconAnalyze }
                                                            />
                                                        </IconButton>}
                                                        {row.VehicleStatus?.Description.toLocaleLowerCase() !== 'pending' && <IconButton>
                                                            <img
                                                                alt="Notify Icon"
                                                                src={ IconNotify }
                                                            />
                                                        </IconButton>}
                                                    </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20]}
                                component="div"
                                labelRowsPerPage="Registros por página"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={(e) => {
                                    setRowsPerPage(+e.target.value)
                                    setPage(0);
                                }}
                            />
                        </Container>
                        :<Container style={{ padding: 0, marginTop: '25px' }}>
                            <Typography
                                variant='h5'
                                className='orangeWord'
                                style={{ marginBottom: '25px' }}
                            >
                                Vehículos del Cliente
                            </Typography>

                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='headTableRow'>
                                            <TableCell align={'center'} >
                                                Vehicle Id
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Marca
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Model
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Year
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Serial Number
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>

                            <Typography
                                variant='h5'
                                style={{ marginTop: '50px', textAlign: 'center' }}
                            >
                                Sin vehículos
                            </Typography>
                        </Container>
                }
                    
            </Container>

            <Footer />
            
            <Modal open={open} handleCloseModal={handleCloseModal} handleCloseModalAcept={handleCloseModalAcept} />
            <ModalErrorUser openError={openError} handleCloseModalError={handleCloseModalError} handleCloseModalErrorAcept={handleCloseModalErrorAcept} data={dataModalError} />
        </Container>
    )
}

export default Client;