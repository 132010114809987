// Import react components
import { useEffect, useState } from 'react'

// Material UI components
import {
    Container,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Stack,
    IconButton,
    Typography
} from '@mui/material'

// Import router dom components
import { useNavigate } from "react-router-dom";

// Import components
import Footer from './../components/footer'
import NavBar from '../components/navBar/navBar'

// Import assets
import IconNotify from './../assets/icons/Icon_Notify_01.png'
import IconView from './../assets/icons/Icon_View_01.png'

// Import services
import  * as RequestServices from './../api/request'

// Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.suppressDeprecationWarnings = true
moment.locale('es-mx')


const Request = () => { 
  let navigate = useNavigate()

  /* Function of table */
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /* Function to redirect */
  const handleGoTo = async (to) => {
    navigate(to)
  }

  /* Function to translate status */
  const statusTranslate = (status) => {
    const s = "" + status

    switch(s.toLocaleLowerCase()) {
      case 'pending':
        return 'Pendiente'
      case 'validated':
        return 'Validado'
      case 'notified':
        return 'Notificado'
      case 'error':
        return 'Error'
      default:
        return s
    }
  }

  useEffect(() => {
    const getRequest = async () => {
      const response = await RequestServices.getRequest()
      //console.log(response)

      if(response.success) {
        const data = response.data.customers

        setRows(data)
      } else {
        //poner alert de error
      }
    }

    getRequest()
  }, [])


  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      <NavBar routes={'Solicitudes'} params={{}} />

      <Container style={{ minHeight: 'calc(100vh - 92px)',  paddingTop: 'calc(50px + 98px)' }}>
        { rows.length !== 0
            ? <Container style={{ padding: 0, marginTop: '25px' }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className='headTableRow'>
                        <TableCell align={'center'} >
                          Customer Id
                        </TableCell>
                        <TableCell align={'center'} >
                          Customer Name
                        </TableCell>
                        <TableCell align={'center'} >
                          Registration Date
                        </TableCell>
                        <TableCell align={'center'} >
                          Status
                        </TableCell>
                        <TableCell align={'center'} >
                          Vehicles Amt
                        </TableCell>
                        <TableCell align={'center'} >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className='bodyTable'>
                      <TableRow className='separatorRow'>
                        <TableCell colSpan={6}></TableCell>
                      </TableRow>
                      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow role="checkbox" tabIndex={ -1 } key={ index } className='bodyTableRows'>
                            <TableCell align={'center'}>
                              { row.UserId }
                            </TableCell>
                            <TableCell align={'left'}>
                              { row.Name ? (row.Name + ' ' + row.MiddleName + (row.LastName ? ' ' + row.LastName : '')) : 'NA' }
                            </TableCell>
                            <TableCell align={'center'}>
                              { moment(row.RegistrationDate).format('dddd[,] DD [de] MMMM [de] YYYY') }
                            </TableCell>
                            <TableCell align={'center'}>
                              { statusTranslate(row.UserStatus?.Description) }
                            </TableCell>
                            <TableCell align={'center'}>
                              { row.Vehicles.length }
                            </TableCell>
                            <TableCell align={'center'}>
                              <Stack 
                                direction="row" 
                                justifyContent="center"
                                alignItems="center" 
                                spacing={3} 
                                className='buttonActions'>
                                  <IconButton
                                    onClick={() => {
                                      handleGoTo(`/solicitudes/cliente/${row.UserId}`)
                                    }}
                                  >
                                    <img
                                      alt="View Icon"
                                      src={ IconView }
                                    />
                                  </IconButton>
                                  <IconButton
                                  >
                                    <img
                                      alt="Notify Icon"
                                      src={ IconNotify }
                                    />
                                  </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  component="div"
                  labelRowsPerPage="Registros por página"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(+e.target.value)
                    setPage(0)
                  }}
                />
              </Container>
            : <Container style={{ padding: 0, marginTop: '25px' }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className='headTableRow'>
                        <TableCell align={'center'} >
                          Customer Id
                        </TableCell>
                        <TableCell align={'center'} >
                          Customer Name
                        </TableCell>
                        <TableCell align={'center'} >
                          Customer Date
                        </TableCell>
                        <TableCell align={'center'} >
                          Status
                        </TableCell>
                        <TableCell align={'center'} >
                          Vehicles Amt
                        </TableCell>
                        <TableCell align={'center'} >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>

                <Typography
                  variant='h5'
                  style={{ marginTop: '50px', textAlign: 'center' }}
                >
                  Sin solicitudes
                </Typography>
              </Container>
        }
      </Container>

      <Footer />
    </Container>
  )
}

export default Request;