import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  './../components/errorHandler'

/* Service to get Client Data and Vehicle Data
@params
    * none
@headers
    * token: string
*/
export const getVehicleData = async (vehicleId) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/vehicles/V1/getVehiclesDetails/${vehicleId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }  
}

/* Service to get Vehicle Events
@params
    * none
@headers
    * token: string
*/
export const getVehicleEvents = async (vehicleId) => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/vehicles/V1/getVehicleEvents/${vehicleId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    } 
}

/* Service to validate Vehicle Data
@params
    * userId: string
    * brand: string
    * model: string
    * year: string
    * seriaNumber: string
    * engineNumber?: string
    * passengers: string
    * powerSource: string
    * licencePlate: string
    * expeditionDate: string
    * expeditionPlace: string
    * ExpirationDate: string
@headers
    * token: string
*/
export const validateVehicleData = async (user, data, vehicleId) => {
    try {
        const body = {
            userId: user.idClient,
            brand: data.brand,
            model: data.model,
            year: data.year,
            serialNumber: data.serialNumber,
            engineNumber: data.engineNumber,
            passengers: data.passengers,
            powerSource: data.powerSource,
            licencePlate: data.licencePlate,
            expeditionDate: data.expeditionDate,
            expeditionPlace: data.expeditionPlace,
            expirationDate: data.expirationDate
        }

        const response = await fetch(`${Constants.API.BASE_URL}/vehicles/V1/validateVehicles/${vehicleId}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    } 
}