// Router dom imports
import { 
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom'

// Pages imports
import Login from './pages/login'
import Menu from './pages/menu'
import Request from './pages/request'
import Client from './pages/client'
import VehiclesCustomer from './pages/vehiclesCustomer'

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />}  />
                <Route path='/menu' element={<Menu />}  />
                <Route path='/solicitudes' element={<Request />}  />
                <Route path='/solicitudes/cliente/:idUser' element={<Client />} />
                <Route path='/solicitudes/cliente/vehiculos/:idVehicle' element={<VehiclesCustomer />} />

            </Routes>
        </BrowserRouter>
    );
}

export default Router
