import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  './../components/errorHandler'

/* Service to create Vehicle Event
@params Invoces
    * rfcEmiter: string
    * nameEmiter: string
    * rfcReceptor: string
    * nameReceptor: string
    * fiscalFolio: string
    * expeditionDate: string
    * certificationDateSAT: string
    * pacCertified: string
    * totalCFDI: string
    * comprobantEffect: string
    * statusCFDI: string
    * statusCancelation: string
@params Fines
    * consultationDate: string
    * source: string
    * haveFines: string
    * quantity?: string
    * fineAmount?: string
    * urlToPay?: string
@headers
    * token: string
*/
export const createVehicleEvents = async (data, vehicleId) => {
    try {
        let body
        let URL = `${Constants.API.BASE_URL}/events/V1/addEvent`
        switch(data.eventType) {
            case 1:
                //console.log("entra1")

                body = {
                    rfcEmiter: data.rfcEmiter,
                    nameEmiter: data.nameEmiter,
                    rfcReceptor: data.rfcReceptor,
                    nameReceptor: data.nameReceptor,
                    fiscalFolio: data.fiscalFolio,
                    expeditionDate: data.expeditionDate,
                    certificationDateSAT: data.certificationDateSAT,
                    pacCertified: data.pacCertified,
                    totalCFDI: data.totalCFDI,
                    comprobantEffect: data.comprobantEffect,
                    statusCFDI: data.statusCFDI,
                    statusCancelation: data.statusCancelation
                }

                URL += `/invoice/${vehicleId}`
                break
            case 2:
                //console.log("entra2")

                body = {
                    consultationDate: data.consultationDate,
                    source: data.source,
                    haveFines: data.haveFines,
                    quantity: data.haveFines.toLowerCase() === 'si' ? data.quantity : '',
                    fineAmount: data.haveFines.toLowerCase() === 'si' ? data.fineAmount : '',
                    urlToPay: data.haveFines.toLowerCase() === 'si' ? data.urlToPay : '',
                }

                URL += `/trafficFine/${vehicleId}`
                break
            case 3:
                body = {}
                break
            default:
                body = {}
                break
        }
        //console.log(body)
        //console.log(URL)

        const response = await fetch(URL, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    } 
}