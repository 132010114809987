const errorCatalog = {
    0: 'Servicio no disponible', 
    1: 'Información faltante o tipos inválidos',
    500: 'Error al conectar con el servidor'
}

const handleError = ({ code , message }) => {
    return {
        success: false,
        message: message
    }
}

export default handleError