// Import react components
import React from 'react'

// Material UI components
import {
    Typography,
    Breadcrumbs,
    Link 
} from '@mui/material'

// Import assets
import IconHome from './../../assets/icons/Home_01.png'

const BreadcrumbsBar = ({ routes, params }) => {
    const pathnames = routes.split('/').filter((x) => x)

    const getParamRoute = (url) => {
        const urlSplit = url.split('/')
        const routeFinal = urlSplit[urlSplit.length - 1]
        //console.log(routeFinal, params)

        if(routeFinal === 'cliente' && params?.clientId) {
            return `${url}/${params.clientId}` 
        }

        return url
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumbs'>
            {pathnames.length !== 0 && <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="/menu"
            >
                <img 
                    src={IconHome}
                    alt="Icono Home"
                    width={24}
                />
            </Link>}
            {pathnames.map((route, index) => {
                const last = index === pathnames.length - 1
                const preUrl = `/${pathnames.slice(0, index + 1).join('/').toLocaleLowerCase()}`;
                const to = getParamRoute(preUrl)

                //console.log(last, to)

                return last 
                    ? (
                        <Typography
                            key={ index }
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            { route }
                        </Typography>
                    )
                    : (
                        <Link
                            key={ index }
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={to}
                        >
                            { route }
                        </Link>
                    )
            })}
        </Breadcrumbs>
  );
}

export default BreadcrumbsBar
