// Material UI components
import {
    Container,
    Grid,
    Typography,
    Card,
    CardActionArea
} from '@mui/material'

// Import router dom components
import { useNavigate } from "react-router-dom";

// Import components
import Footer from './../components/footer'
import NavBar from '../components/navBar/navBar'

// Import assets
import IconRequest from './../assets/icons/Icon_Request_Menu.png'
import IconMonitoring from './../assets/icons/Icon_Monitor_Menu.png'
import IconUsers from './../assets/icons/Icon_Users_Menu.png'

import BlockChain from './../assets/icons/Logo_Blocks_Orange_01.png'
import Transacctions from './../assets/icons/Transacctions.png'
import Finance from './../assets/icons/Finance.png'


const Menu = () => { 
    let navigate = useNavigate()

    const modules = [
        {
            title: 'Solicitudes',
            iconUrl: IconRequest,
            ref: '/solicitudes',
            alt: 'Icono Solicitudes'
        },
        {
            title: 'Monitoring',
            iconUrl: IconMonitoring,
            ref: '/menu',
            alt: 'Icono '
        },
        {
            title: 'Users',
            iconUrl: IconUsers,
            ref: '/menu',
            alt: 'Icono Users'
        },
        {
            title: 'BlockChain',
            iconUrl: BlockChain,
            ref: '/menu',
            alt: 'Icono BlockChain'
        },
        {
            title: 'Transacciones',
            iconUrl:Transacctions,
            ref: '/menu',
            alt: 'Icono Transacciones'
        },
        {
            title: 'Finance',
            iconUrl: Finance,
            ref: '/menu',
            alt: 'Icono Finance'
        },
    ]

    const handleGoTo = async (to) => {
        navigate(to)
    }

    return (
        <Container>
            <NavBar routes={''} params={{}} />

            <Container style={{ minHeight: 'calc(100vh - 92px)',  paddingTop: 'calc(50px + 98px)' }}>
                <Grid 
                    container
                    rowSpacing={6} 
                    columnSpacing={2}
                >
                    {modules.map((module, index) => {
                        return (
                            <Grid 
                                container
                                item 
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                key={index}
                                style={{ textAlign: 'center' }}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <Card className='homeCard'>
                                    <CardActionArea
                                        onClick={() => {
                                            handleGoTo(module.ref)
                                        }}
                                    >
                                        <img 
                                            height={120}
                                            width={110}
                                            src={ module.iconUrl }
                                            alt={ module.alt }
                                        />
                                        <Typography 
                                            variant="h5"
                                            py={2}
                                        >
                                            { module.title }
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                                
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>

            <Footer />
        </Container>
    )
}

export default Menu;