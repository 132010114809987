import React, { useEffect, useState } from 'react'

// Material UI components
import {
    Container,
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    InputAdornment,
    IconButton
} from '@mui/material'
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material'


// Import router dom components
import { useNavigate } from "react-router-dom"

//React Hook Form imports
import { useForm } from "react-hook-form"

// Import services
import * as AuthServices from './../api/auth'

// Import assets
import LogoCryptoMain from './../assets/imgs/Logo_Crypto_Main.png'
import BackLogoCryptoMain from './../assets/imgs/Img_Block.png'


const Login = () => { 
    let navigate = useNavigate()

    /* Form Login */
    const { register, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = async (data) => {
        //console.log(data)

        const response = await AuthServices.Login(data)

        if(response.success) {
            //console.log(response)

            const user = {
                name: response.data.name,
                lastLogin: response.data.lastLogin
            }
    
            localStorage.setItem('user', JSON.stringify(user))
    
            // Add token cookie that expires in 24 hours
            const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
            document.cookie = `jwtToken=${response.data.authorizationToken}; expires=${expires}; path=/`;
    
            // Add token refresh cookie that expires in 5 days
            const expiresRefresh = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString();
            document.cookie = `jwtRefresh=${response.data.refreshToken}; expires=${expiresRefresh}; path=/`;

            navigate('/menu')
        } else {
            //aqui va el alert de error
        }
    }

    /* Show or hide password */
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }


    useEffect(() => {
        const verifySession = async () => {
            const response = await AuthServices.getMe()

            if(response.success) {
                //console.log(response)

                const user = {
                    name: response.data.name,
                    lastLogin: response.data.lastLogin
                }
        
                localStorage.setItem('user', JSON.stringify(user))

                navigate("/menu")
            }
        }
      
          
        verifySession()
    }, [])

    return (
        <Container maxWidth="xl" style={{ padding: 0 }}>
            <Grid container style={{ height: '100vh' }}>
                <Grid 
                    container 
                    item
                    direction="row"
                    justifyContent="Center"
                    alignItems="flex-start"
                    className='logoLoginMain'
                    style={{ display: 'block' }}
                    rowSpacing={2}
                    xs={12} 
                    sm={12}
                    md={4}
                >
                    <Grid
                        item
                        style={{ textAlign: 'center' }}
                        xs={12}
                    >
                        <img
                            className='logoMain'
                            src={LogoCryptoMain}
                            alt='Logo Crypto Main'
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>Monica</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography variant="body1" align="center">(Monitoring & Customer Assistant)</Typography>
                    </Grid>
                    
                    
                    
                    
                </Grid>

                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className='formLogin'
                    xs={12}
                    sm={12}
                    md={8}
                >
                    <img className='logoBackground' src={BackLogoCryptoMain} alt='' />
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ textAlign: 'center' }}>
                        <TextField 
                            margin="normal"
                            fullWidth
                            className='textFieldLogin'
                            id="email"
                            label="Correo electrónico"
                            autoComplete="email"
                            autoFocus
                            size="small"
                            {...register("email", {
                                required: "Correo electrónico requerido.",
                                pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Dirección de correo electrónico inválida.",
                                },
                            })}
                            error={!!errors?.email}
                            helperText={errors?.email ? errors.email.message : null}
                        />

                        <TextField 
                            margin="normal"
                            fullWidth
                            className='textFieldLogin'
                            label="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                            {...register("password", {
                                required: "Contraseña requerida."
                            })}
                            error={!!errors?.password}
                            helperText={errors?.password ? errors.password.message : null}
                        />
                        
                        <br />

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                backgroundColor: "#5C5470"
                            }}
                        >
                            Acceder
                        </Button>
                    </Box>
                </Grid>

            </Grid>
        </Container>
    )
}

export default Login;