import React, { useState, useEffect } from 'react';

// Material UI components
import {
    AppBar, 
    Toolbar, 
    Typography, 
    Grid, 
    Avatar, 
    Container, 
    Box, 
    Tooltip, 
    IconButton, 
    Menu, 
    MenuItem,
} from '@mui/material'

// Import Router dom components
import { Link, useNavigate } from "react-router-dom";

// Import assets
import LogoCryptoMain from './../../assets/imgs/Logo_Crypto_Main.png'
import ProfileIcon from './../../assets/icons/Icon_User_01.png'

// Import components
import BreadcrumbsBar from './breadcrumbs';

// Import services
import * as AuthServices from './../../api/auth'

//Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.suppressDeprecationWarnings = true
moment.locale('es-mx')


const NavBar = ({ routes, params }) => { 
    let navigate = useNavigate()
    const user =  JSON.parse(localStorage.getItem('user'))

    /* Events to menu */
    const [anchorUser, setAnchorUser] = useState(false)

    const logout = async () => {
        await AuthServices.Logout()

        navigate("/")
    }


    useEffect(() => {
        const verifySession = async () => {
            const response = await AuthServices.getMe()

            if(response.success) {
                //console.log(response)

                const user = {
                    name: response.data.name,
                    lastLogin: response.data.lastLogin
                }
        
                localStorage.setItem('user', JSON.stringify(user))

                navigate("/menu")
            }
        }
      
        if(!user) verifySession()
    }, [])    

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={0} style={{ backgroundColor: '#FFFFFF' }}>
                <Container maxWidth='xl' style={{ margin: 0, maxWidth: '100%' }}>
                    <Toolbar disableGutters style={{ maxHeight: '96px', paddingTop: '5px' }}>

                        {/* Logo */}
                        <Link
                            to="/menu"
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <img
                                src={LogoCryptoMain}
                                alt="Logo Inova Evento"
                                style={{ height: '72px' }}
                            />
                        </Link>

                        {/* Date of access */}
                        <Grid container item>
                            <Grid container item>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        align="right"
                                        sx={{
                                            mr: 2,
                                            display: { xs: 'none', sm: 'block', md: 'block' },
                                            flexGrow: 1,
                                            color: '#000000'
                                        }}
                                    >
                                        {moment(user?.lastLogin).format('dddd[,] DD [de] MMMM [de] YYYY[,] HH:mm:ss')}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Profile Icon (with menu) and name */}
                        <Box sx={{ flexGrow: 0, textAlign: 'center' }}>
                            {/* Profile Icon */}
                            <Tooltip title="Opciones">
                                <IconButton
                                    onClick={() => {
                                        setAnchorUser(true)
                                    }}
                                    sx={{ p: 0 }}
                                >
                                    <Avatar
                                        src={ProfileIcon}
                                        sx={{ width: 65, height: 65 }}
                                        alt="User's profile picture"
                                    />
                                </IconButton>
                            </Tooltip>
                        
                            {/* Menu in profile */}
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-settings"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={anchorUser}
                                onClose={() => {
                                    setAnchorUser(false)
                                }}
                            >
                                <MenuItem onClick={() => {
                                    setAnchorUser(false)
                                }}>
                                    <Typography textAlign="center" onClick={logout}>Cerrar sesión</Typography>
                                </MenuItem>
                            </Menu>

                            {/* Name of user */}
                            <Typography
                                variant="subtitle1"
                                align="center"
                                noWrap
                                sx={{
                                    display: { xs: 'none', sm: 'block', md: 'block' },
                                    flexGrow: 1,
                                    color: '#000000'
                                }}
                            >
                                { user?.name }
                            </Typography>
                        </Box>
                    </Toolbar>
                </Container>

                <Container>
                    <BreadcrumbsBar routes={routes} params={params} />
                </Container>
            </AppBar>
        </Box>
    )
}

export default NavBar;