import React, { useEffect } from 'react';

// Material UI components
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem
} from '@mui/material'

//React Hook Form imports
import { useForm } from "react-hook-form"


const ModalErrorGeneralVehicle = ({ openError, handleCloseModalError, handleCloseModalErrorAcept, dataModal }) => {
    /* Functions of forms if data.type is vehicle */
    const { register, handleSubmit, formState: { errors }, reset} = useForm()
    const onSubmit = async (data,) => {
        //console.log(data) 
        const message = data.message   
        handleCloseModalErrorAcept(message)
    }

    useEffect(() => {
        reset({message: null})
    }, [])

    /* Function to close modal */
    const closeModal = async () => {
        reset({message: null})
        handleCloseModalError()
    }

    return (
        <Dialog
            open={openError}
            onClose={closeModal}        
            hideBackdrop ={true}
            className='modalClient'
            fullWidth
            maxWidth='md'
        >
            <DialogContent className='modalContentEvent'>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <Typography
                        variant='h3'
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {dataModal.message}
                    </Typography>

                    {/* Type to notification */}        
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                        sx={{
                            mt: 1,
                            mx: 4,
                            textAlign: "center",
                            minWidth: "-webkit-fill-available",
                        }}
                    >
                        {/* Select type to error (vehicle) */}
                        <Grid
                            container
                            item
                            className="formEvent"
                            columnSpacing={1}
                            rowSpacing={0}
                            xs={12}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    size="small"
                                    placeholder='Indica detalles del mensaje a notificar'
                                    style={{ marginTop: 5, marginBottom: 5 }}
                                    defaultValue=''
                                    {...register("message", {
                                        required: "Debe indicarse el mensaje detallado a notificar."
                                    })}
                                    error={!!errors?.message}
                                    helperText={!!errors?.message ? errors.message.message : null}
                                >
                                </TextField>
                            </Grid>

                            {/* Button actions */}
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                                className='buttonsModal'
                                xs={12}
                            >

                                <Button 
                                    autoFocus
                                    size="small"
                                    variant='contained'
                                    type="submit"
                                >
                                    Notificar
                                </Button>
                                <Button 
                                    size="small"
                                    variant='contained'
                                    onClick={closeModal}
                                >
                                    Regresar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>


                    
                </Grid>

                
            </DialogContent>
        </Dialog>
    );
}

export default ModalErrorGeneralVehicle