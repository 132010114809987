import React from 'react';

// Material UI components
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid
} from '@mui/material'


const Modal = ({ open, handleCloseModal, handleCloseModalAcept }) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}        
      hideBackdrop ={true}
      className='modalClient'
      fullWidth
      maxWidth='md'
    >
      <DialogContent className='modalContent'>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Typography
            variant='h4'
          >
            Esta seguro de aprobar al usuario,<br />estos datos no se pueden cambiar
          </Typography>

          {/* Button actions */}
          <Grid
            container
            item
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className='buttonsModal'
            xs={12}
          >

            <Button 
              autoFocus
              size="small"
              variant='contained'
              onClick={handleCloseModalAcept} 
            >
              Aprobar
            </Button>
            <Button 
              size="small"
              variant='contained'
              onClick={handleCloseModal}
            >
              Regresar
            </Button>
          </Grid>
        </Grid>

          
      </DialogContent>
    </Dialog>
  );
}

export default Modal