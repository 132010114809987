// Material UI components
import {
    Container,
    Typography
} from '@mui/material'

const Footer = () => {
    return (
        <Container maxWidth="xl" className='footer' >
            <Typography variant="caption">Monica</Typography>
            <br />
            <Typography variant="caption">Develop By Digital Ignition - Copyright 2022</Typography>
        </Container>
    )
}

export default Footer;