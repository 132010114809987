// Import react components
import { useEffect, useState } from 'react'

// Material UI components
import {
    Container,
    Grid,
    Typography,
    TextField,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Stack,
    IconButton
} from '@mui/material'

// Import router dom components
import { useNavigate, useParams } from "react-router-dom"

// Import components
import Footer from './../components/footer'
import NavBar from './../components/navBar/navBar'
import Modal from './../components/modals/modalClient'
import ModalEvent from './../components/modals/modalEvent'
import ModalErrorVehicle from './../components/modals/modalErrorVehicle'
import ModalErrorGeneralVehicle from './../components/modals/modalEventErrorGeneral'

// Import assets
import IconView from './../assets/icons/Icon_View_01.png'
import IconDocument from './../assets/icons/Icon_Document_01.png'
import IconNotify from './../assets/icons/Icon_Notify_01.png'
import VehicleDefault from './../assets/imgs/Vehicle_Default.png'

// Import services
import * as VehicleServices from './../api/vehicles'
import * as NotificationServices from './../api/notifications'

// Moment import
import moment from 'moment'
import 'moment/locale/es'
moment.suppressDeprecationWarnings = true
moment.locale('es-mx')


const VehiclesCustomer = () => { 
    let navigate = useNavigate()
    const { idVehicle } = useParams()

    /* Function to open modal */
    const [open, setOpen] = useState(false)
    const handleOpenModal = async () => {
        if(
            vehicle.brand !== '' &&
            vehicle.model !== '' &&
            vehicle.year !== '' &&
            vehicle.serialNumber !== '' &&
            //vehicle.engineNumber !== '' &&
            vehicle.passengers !== '' &&
            vehicle.powerSource !== '' &&
            vehicle.licencePlate !== '' &&
            vehicle.expeditionDate !== '' &&
            vehicle.expeditionPlace !== '' &&
            vehicle.expirationDate !== ''
        ) {
            setOpen(true)
        } else {
            setActiveValidations(true)
        }
    }
    const handleCloseModal = async () => {
        setOpen(!open)
    }
    const handleCloseModalAcept = async () => {
        const response = await VehicleServices.validateVehicleData(user, vehicle, idVehicle)

        if(response.success) {
            getDataVehicle()
            setOpen(!open)
        } else {
            // alert con error
        }

    }

    /* Function to open modal error */
    const [openError, setOpenError] = useState(false)
    const [dataModalError, setDataModalError] = useState({
        title: '',
        message: ''
    })
    const handleOpenModalError = async () => {
        setOpenError(true)
        setDataModalError({
            title: '',
            message: 'Notificar inconsistencias con la validacion del vehiculo'
        })
    }
    const handleCloseModalError = async () => {
        setOpenError(!openError)
        setDataModalError({
            title: '',
            message: ''
        })
    }
    const handleCloseModalErrorAcept = async (type) => {
        //console.log(dataModalError, type)
        const response = await NotificationServices.NotificateVehicleError(user.idClient, vehicle.vehicleId, type)

        if(response.success) {
            setOpenError(!openError)
            setDataModalError({
                title: '',
                message: ''
            })
        } else {
            // alert indicando error
        }
    }

    /* Function to open modal error general */
    const [openErrorGeneral, setOpenErrorGeneral] = useState(false)
    const [dataModalErrorGeneral, setDataModalErrorGeneral] = useState({
        title: '',
        message: ''
    })
    const handleOpenModalErrorGeneral = async () => {
        setOpenErrorGeneral(true)
        setDataModalErrorGeneral({
            title: '',
            message: 'Notificar información encontrada sobre el auto'
        })
    }
    const handleCloseModalErrorGeneral = async () => {
        setOpenErrorGeneral(!openErrorGeneral)
        setDataModalErrorGeneral({
            title: '',
            message: ''
        })
    }
    const handleCloseModalErrorGeneralAcept = async (message) => {
        //console.log(dataModalErrorGeneral, message)
        const response = await NotificationServices.NotificateVehicleErrorGeneral(user.idClient, vehicle.vehicleId, message)

        if(response.success) {
            setOpenErrorGeneral(!openErrorGeneral)
            setDataModalErrorGeneral({
                title: '',
                message: ''
            })
        } else {
            // alert indicando error
        }
    }

    /* Function to open modal events */
    const [openEvent, setOpenEvent] = useState(false)
    const handleCloseModalEvent = async () => {
        setOpenEvent(!openEvent)
    }
    const handleCloseModalAceptEvent = async () => {
        getVehicleEvents()
        setOpenEvent(!openEvent)
    }

    /* Function of data of user */
    const [user, setUser] = useState({
        idClient: '',
        name: '',
        middleName: '',
        lastName: ''
    })

    /* Function of data of vehicle */
    const [vehicle, setVehicle] = useState({
        vehicleId: '',
        brand: '',
        model: '',
        year: '',
        serialNumber: '',
        engineNumber: '',
        passengers: '',
        powerSource: '',
        licencePlate: '',
        expeditionDate: '',
        expeditionPlace: '',
        expirationDate: '',
        status: '',
        vehiclePicture: ''
    })
    const [activeValidations, setActiveValidations] = useState(false)
    const [documents, setDocuments] = useState([])
    
    /* Function of table */
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    
    /* Function go to */
    const handleGoTo = async (to) => {
        navigate(to)
    }

    /* Function to translate status */
    const statusTranslate = (status) => {
        const s = "" + status

        switch(s.toLocaleLowerCase()) {
        case 'pending':
            return 'Pendiente'
        case 'validated':
            return 'Validado'
        case 'notified':
            return 'Notificado'
        case 'error':
            return 'Error'
        default:
            return s
        }
    }

    /* Function to get data of vehicle */
    const getDataVehicle = async () => {
        const response = await VehicleServices.getVehicleData(idVehicle)
        //console.log(response)

        if(response.success) {
            const data = response.data[0]
            const dataUser = data.User

            setUser({
                idClient: dataUser.UserId,
                name: dataUser.Name,
                middleName: dataUser.MiddleName,
                lastName: dataUser.LastName ? dataUser.LastName : ''
            })

            setVehicle({
                ...vehicle,
                vehicleId: data.VehicleId,
                brand: data.Brand ? data.Brand : '',
                model: data.Model ? data.Model : '',
                year: data.Year ? data.Year : '',
                serialNumber: data.SerialNumber ? data.SerialNumber : '',
                engineNumber: data.EngineNumber ? data.EngineNumber : '',
                passengers: data.Passengers ? data.Passengers : '',
                powerSource: data.PowerSource ? data.PowerSource : '',
                licencePlate: data.RegistrationCards[0].LicencePlate ? data.RegistrationCards[0].LicencePlate : '',
                expirationDate: data.RegistrationCards[0].ExpirationDate ?  moment(data.RegistrationCards[0].ExpirationDate).format('DD / MM / YYYY') : '',
                expeditionPlace: data.RegistrationCards[0].ExpeditionPlace ? data.RegistrationCards[0].ExpeditionPlace : '',
                expeditionDate: data.RegistrationCards[0].ExpeditionDate ?  moment(data.RegistrationCards[0].ExpeditionDate).format('DD / MM / YYYY') : '',
                status: data.VehicleStatus?.Description,
                vehiclePicture: data.VehiclePictures?.PictureURL
            })

            var documents = []
            for(var i in data.VehicleDocuments) {
                const document = data.VehicleDocuments[i]

                documents.push({
                    idDocument: document.DocumentId,
                    documentType: document.DocumentTypes?.Description,
                    documentUrl: document.URL
                })
            }

            setDocuments(documents)
        } else {
            //aler de error
        }
    }

    /* Function to get vehicle events */
    const getVehicleEvents = async () => {
        const response = await VehicleServices.getVehicleEvents(idVehicle)
        //console.log(response)

        if(response.success) {
            const data = response.data.vehicleEvents

            setRows(data)
        } else {
            // alert con error
        }
    }

    useEffect(() => {
        getDataVehicle()
        getVehicleEvents()

        //console.log(idVehicle)
    }, [])

    return (
        <Container style={{ padding: 0 }}>
            <NavBar routes={'Solicitudes/Cliente/Vehiculos'} params={{clientId: user.idClient}} />

            <Container style={{ minHeight: 'calc(100vh - 92px)',  paddingTop: 'calc(50px + 98px)' }}>
                <Container  style={{ padding: 0, marginTop: '25px' }}>
                    <Grid
                        container
                        columnSpacing={4}
                    >
                        {/* Client data */}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            {/* Id Client */}
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Id Cliente: <span className='orangeWord'> { user.idClient } </span>
                                </Typography>
                            </Grid>

                            {/* Name */}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Nombre: <span className='orangeWord'> { user.name + ' ' + user.middleName + ' ' + user.lastName } </span> 
                                </Typography>
                            </Grid>

                            {/* Wallet Id */}
                            {/* <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Wallet Id: <span className='orangeWord'> { user.walletId } </span>
                                </Typography>
                            </Grid> */}
                        </Grid>

                        {/* Vechicle Documents */}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='h6'
                                    className='orangeWord'
                                >
                                    Documentos del Vehículo
                                </Typography>
                            </Grid>

                            {/* Documents */}
                            <Grid
                                item
                                xs={12}
                            >
                                {
                                    documents.length !== 0
                                        ?    <Paper 
                                                elevation={0} 
                                                className='cardDocuments'
                                            >

                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    {
                                                        documents.map((document, index) => {
                                                            return (
                                                                <IconButton
                                                                    key={ index }
                                                                    onClick={() => {
                                                                        window.open(`${document.documentUrl}`, "_blank")
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="Document Icon"
                                                                        src={ IconDocument }
                                                                        width={32}
                                                                        height={32}
                                                                    />
                                                                    { document.documentType }
                                                                </IconButton>
                                                            )
                                                        })
                                                    }
                                                        
                                                </Stack>
                                            </Paper>
                                        :   <Typography
                                                variant='subtitle1'
                                            >
                                                No se cargaron documentos
                                            </Typography>
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>

                <Container style={{ padding: 0, marginTop: '25px' }}>
                    <Grid
                        container
                        item
                        columnSpacing={1}
                        rowSpacing={0}
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        {/* Avatar */}
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            sm={3}
                            md={3}
                        >
                            <img alt='CarImage' src={vehicle.vehiclePicture ? vehicle.vehiclePicture : VehicleDefault} className='vehiclePicture' />
                        </Grid>

                        {/* Vehicle data 1/2 */}
                        <Grid
                            container
                            item
                            columnSpacing={1}
                            xs={12}
                            sm={5}
                            md={5}
                        >
                            {/* Id Vehicle */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Vehicle Id: 
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                <Typography
                                    variant='body1'
                                    className='orangeWord'
                                >
                                    { vehicle.vehicleId }
                                </Typography>
                            </Grid>

                            {/* Brand */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Brand{vehicle.brand.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}: 
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.brand.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.brand }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    brand: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.brand.length === 0 && activeValidations }
                                            helperText={vehicle?.brand.length === 0 && activeValidations ? "La marca es obligatoria" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.brand }
                                        </Typography>
                                }
                            </Grid>

                            {/* Model */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Model{vehicle.model.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.model.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.model }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    model: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.model.length === 0 && activeValidations }
                                            helperText={vehicle?.model.length === 0 && activeValidations ? "El modelo es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.model }
                                        </Typography>
                                }
                                
                            </Grid>

                            {/* Year */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Year{vehicle.year.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.year.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.year }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    year: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.year.length === 0 && activeValidations }
                                            helperText={vehicle?.year.length === 0 && activeValidations ? "El año es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.year }
                                        </Typography>
                                }
                            </Grid>

                            {/* Serial Number */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Serial Number{vehicle.serialNumber.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.serialNumber.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.serialNumber }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    serialNumber: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.serialNumber.length === 0 && activeValidations }
                                            helperText={vehicle?.serialNumber.length === 0 && activeValidations ? "El numero de serie es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.serialNumber }
                                        </Typography>
                                }
                            </Grid>

                            {/* Engine Number */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Engine Number{/* vehicle.engineNumber.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: '' */}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {(vehicle.engineNumber.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending') && vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.engineNumber }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    engineNumber: e.target.value
                                                })
                                            }}
                                            /* error={ vehicle?.engineNumber.length === 0 && activeValidations }
                                            helperText={vehicle?.engineNumber.length === 0 && activeValidations ? "El numero de motor es obligatorio" : null} */
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.engineNumber }
                                        </Typography>
                                }
                            </Grid>

                            {/* Passengers */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Passengers{vehicle.passengers.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.passengers.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.passengers }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    passengers: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.passengers.length === 0 && activeValidations }
                                            helperText={vehicle?.passengers.length === 0 && activeValidations ? "El numero de pasajeros es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.passengers }
                                        </Typography>
                                }
                            </Grid>

                            {/* Power Source */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Power Source{vehicle.powerSource.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.powerSource.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.powerSource }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    powerSource: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.powerSource.length === 0 && activeValidations }
                                            helperText={vehicle?.powerSource.length === 0 && activeValidations ? "El power source es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.powerSource }
                                        </Typography>
                                }
                            </Grid>
                        </Grid>

                        {/* Vehicle data 2/2 */}
                        <Grid
                            container
                            item
                            columnSpacing={1}
                            xs={12}
                            sm={4}
                            md={4}
                            
                            style={{ height: 'fit-content' }}
                        >
                            {/* Licence Plate */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Licence Plate{vehicle.licencePlate.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}: 
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.licencePlate.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.licencePlate }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    licencePlate: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.licencePlate.length === 0 && activeValidations }
                                            helperText={vehicle?.licencePlate.length === 0 && activeValidations ? "La placa de circulación es obligatoria" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.licencePlate }
                                        </Typography>
                                }
                            </Grid>

                            {/* Expedition Date */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Expedition Date{vehicle.expeditionDate.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.expeditionDate.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            type='date'
                                            value={ vehicle.expeditionDate }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    expeditionDate: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.expeditionDate.length === 0 && activeValidations }
                                            helperText={vehicle?.expeditionDate.length === 0 && activeValidations ? "La fecha de expedición es obligatoria" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.expeditionDate }
                                        </Typography>
                                }
                                
                            </Grid>

                            {/* Expedition Place */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Expedition Place{vehicle.expeditionPlace.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.expeditionPlace.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            value={ vehicle.expeditionPlace }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    expeditionPlace: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.expeditionPlace.length === 0 && activeValidations }
                                            helperText={vehicle?.expeditionPlace.length === 0 && activeValidations ? "El lugar de expedición es obligatorio" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.expeditionPlace }
                                        </Typography>
                                }
                            </Grid>

                            {/* Expiration Date */}
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item
                                xs={5}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    Expiration Date{vehicle.expirationDate.length === 0 && vehicle.status.toLocaleLowerCase() === 'pending' ? <span className="text-danger">*</span>: ''}:
                                </Typography>
                            </Grid>
                            <Grid
                                container   
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item
                                xs={7}
                            >
                                {vehicle.expirationDate.length === 0 || vehicle.status.toLocaleLowerCase() === 'pending'
                                    ?   <TextField 
                                            fullWidth
                                            className='inputStyle'
                                            variant="standard" 
                                            type='date'
                                            value={ vehicle.expirationDate }
                                            onChange={(e) => {
                                                setVehicle({
                                                    ...vehicle,
                                                    expirationDate: e.target.value
                                                })
                                            }}
                                            error={ vehicle?.expirationDate.length === 0 && activeValidations }
                                            helperText={vehicle?.expirationDate.length === 0 && activeValidations ? "La fecha de expiración es obligatoria" : null}
                                        />
                                    :   <Typography
                                            variant='body1'
                                            className='orangeWord'
                                        >
                                            { vehicle.expirationDate }
                                        </Typography>
                                }
                            </Grid>

                            {/* Button to aprove vehicle */}
                            {vehicle.status.toLocaleLowerCase() === 'pending' && <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                xs={12}
                            >
                                <Button 
                                    size="small"
                                    variant='contained'
                                    style={{ backgroundColor: '#000000', marginTop: '15px' }}
                                    onClick={handleOpenModal}
                                >
                                    Aprobar vehículo
                                </Button>
                            </Grid>}

                            {/* Button to send notification to user about your vehicle */}
                            {vehicle.status.toLocaleLowerCase() === 'pending' && vehicle.status.toLocaleLowerCase() !== 'notified' && <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                xs={12}
                            >
                                <Button 
                                    size="small"
                                    variant='contained'
                                    style={{ backgroundColor: '#000000', marginTop: '15px' }}
                                    onClick={handleOpenModalError}
                                >
                                    Error
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Container>

                {
                    rows.length !== 0  
                        ? <Container style={{ padding: 0, marginTop: '25px' }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        variant='h5'
                                        className='orangeWord'
                                        style={{ marginBottom: '25px' }}
                                    >
                                        Ánalisis del Vehículo
                                    </Typography>
                                </Grid>

                                {vehicle.status.toLocaleLowerCase() !== 'pending' && <Grid
                                    item
                                >
                                    <Button 
                                        size="small"
                                        variant='contained'
                                        style={{ backgroundColor: '#000000', marginBottom: '25px' }}
                                        onClick={() => {
                                            setOpenEvent(true)
                                        }}
                                    >
                                        Nuevo Evento
                                    </Button>
                                </Grid>}
                            </Grid>

                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='headTableRow'>
                                            <TableCell align={'center'} >
                                                Event Id
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Event Type
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Event Date
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Status
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Source
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='bodyTable'>
                                        <TableRow className='separatorRow'>
                                            <TableCell colSpan={6}></TableCell>
                                        </TableRow>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                                <TableRow role="checkbox" tabIndex={ -1 } key={ index } className='bodyTableRows'>
                                                    <TableCell align={'center'}>
                                                        { row.EventId }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.EventTypes?.Description }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { moment(row.Date).format('dddd[,] DD [de] MMMM [de] YYYY') }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { statusTranslate(row.VehicleEventStatus?.Description) }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        { row.Source }
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                    <Stack 
                                                        direction="row" 
                                                        justifyContent="center"
                                                        alignItems="center" 
                                                        spacing={3} 
                                                        className='buttonActions'>
                                                        {/* <IconButton>
                                                            <img
                                                                alt="View Icon"
                                                                src={ IconView }
                                                            />
                                                        </IconButton> */}
                                                        <IconButton>
                                                            <img
                                                                alt="Notify Icon"
                                                                src={ IconNotify }
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20]}
                                component="div"
                                labelRowsPerPage="Registros por página"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={(e) => {
                                    setRowsPerPage(+e.target.value)
                                    setPage(0);
                                }}
                            />

                            {vehicle.status.toLocaleLowerCase() !== 'pending' && <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{ marginTop: '15px' }}
                            >
                                <Grid
                                    item
                                >
                                    <Button 
                                        size="small"
                                        variant='contained'
                                        style={{ backgroundColor: '#DF6009', marginBottom: '25px' }}
                                        onClick={handleOpenModalErrorGeneral}
                                    >
                                        Notificar
                                    </Button>
                                </Grid>
                            </Grid>}
                        </Container>
                        :<Container style={{ padding: 0, marginTop: '25px' }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        variant='h5'
                                        className='orangeWord'
                                        style={{ marginBottom: '25px' }}
                                    >
                                        Ánalisis del Vehículo
                                    </Typography>
                                </Grid>

                                {vehicle.status.toLocaleLowerCase() !== 'pending' && <Grid
                                    item
                                >
                                    <Button 
                                        size="small"
                                        variant='contained'
                                        style={{ backgroundColor: '#000000', marginBottom: '25px' }}
                                        onClick={() => {
                                            setOpenEvent(true)
                                        }}
                                    >
                                        Nuevo Evento
                                    </Button>
                                </Grid>}
                            </Grid>

                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className='headTableRow'>
                                            <TableCell align={'center'} >
                                                Event Id
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Event Type
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Event Date
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Status
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Source
                                            </TableCell>
                                            <TableCell align={'center'} >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>

                            <Typography
                                variant='h5'
                                style={{ marginTop: '50px', textAlign: 'center' }}
                            >
                                Sin vehículos
                            </Typography>

                            {vehicle.status.toLocaleLowerCase() !== 'pending' && <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{ marginTop: '15px' }}
                            >
                                <Grid
                                    item
                                >
                                    <Button 
                                        size="small"
                                        variant='contained'
                                        style={{ backgroundColor: '#DF6009', marginBottom: '25px' }}
                                        onClick={handleOpenModalErrorGeneral}
                                    >
                                        Notificar
                                    </Button>
                                </Grid>
                            </Grid>}
                        </Container>
                }
                    
            </Container>


            <Footer />
            
            <Modal open={open} handleCloseModal={handleCloseModal} handleCloseModalAcept={handleCloseModalAcept} />
            <ModalErrorVehicle openError={openError} handleCloseModalError={handleCloseModalError} handleCloseModalErrorAcept={handleCloseModalErrorAcept} dataModal={dataModalError} />
            <ModalErrorGeneralVehicle openError={openErrorGeneral} handleCloseModalError={handleCloseModalErrorGeneral} handleCloseModalErrorAcept={handleCloseModalErrorGeneralAcept} dataModal={dataModalErrorGeneral} />
            <ModalEvent open={openEvent} handleCloseModalEvent={handleCloseModalEvent} handleCloseModalAceptEvent={handleCloseModalAceptEvent} vehicleId={idVehicle} />
        </Container>
    )
}

export default VehiclesCustomer;