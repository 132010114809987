import React, { useState, useEffect } from "react"

// Material UI components
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"

//React Hook Form imports
import { useForm } from "react-hook-form"

//Import services
import * as EventsServices from './../../api/events'


const ModalEvent = ({open, handleCloseModalEvent, handleCloseModalAceptEvent, vehicleId}) => {
  const types = [
    { id: 1, description: "Factura" },
    { id: 2, description: "Infracciones" },
    { id: 3, description: "Siniestro" },
  ];

  /* Functions of forms */
  const { register, handleSubmit, formState: { errors }, reset} = useForm()
  const onSubmit = async (data) => {
    //console.log(data)
    const response = await EventsServices.createVehicleEvents(data, vehicleId)
    console.log(response)

    if(response.success) {
      reset()
      setEventTypeSelected({
        id: 0,
        description: "",
      })
      setHaveFines(0)
      handleCloseModalAceptEvent()
    } else {
      // alert con error
    }
  }

  /* Function to event types */
  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypeSelected, setEventTypeSelected] = useState({
    id: 0,
    description: "",
  })

  /* Function to fines */
  const [haveFines, setHaveFines] = useState(0)

  /* Function to close modal */
  const closeModal = async () => {
    reset()
    setEventTypeSelected({
      id: 0,
      description: "",
    })
    setHaveFines(0)
    handleCloseModalEvent()
  }

  useEffect(() => {
    const getEventTypes = async () => {
      setEventTypes(types)
    };

    getEventTypes()
  }, [])

  return (
    <Dialog
      open={open}
      //onClose={handleCloseModalEvent}
      hideBackdrop={true}
      className="modalClient"
      fullWidth
      maxWidth="md"
    >
      <DialogContent className="modalContentEvent">
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {/* Title and close button */}
          <Grid container item xs={12}>
            {/* Title of modal */}
            <Grid item xs={11}>
              <Typography variant="h4">Ingrese los datos del Evento</Typography>
            </Grid>

            {/* Close Button */}
            <Grid item xs={1}>
              <IconButton
                className="closeModalEvent"
                onClick={closeModal}
              >
                <CloseIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
          </Grid>

          {/* Events */}          
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{
              mt: 1,
              mx: 4,
              textAlign: "center",
              minWidth: "-webkit-fill-available",
            }}
          >
            {/* Select type of event */}
            <Grid
              container
              item
              className="formEvent"
              columnSpacing={1}
              rowSpacing={0}
              xs={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={6}
              >
                <Typography variant="body1">Tipo de evento:</Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={6}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  size="small"
                  style={{ marginTop: 5, marginBottom: 5 }}
                  value={eventTypeSelected.id}
                  {...register("eventType", {
                    required: "Debe indicarse el tipo de evento.",
                    onChange: ((e) => {
                      for(var i in eventTypes) {
                        if(parseInt(e.target.value) === eventTypes[i].id) {
                          setEventTypeSelected({
                            id: eventTypes[i].id,
                            description: eventTypes[i].description
                          })
  
                          break
                        }
                      }
                    })
                  })}
                  error={!!errors?.eventType}
                  helperText={!!errors?.eventType ? errors.eventType.message : null}
                >
                  {eventTypes.map((event, index) => (
                    <MenuItem key={index} value={event.id}>
                      {event.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* Was selected invoces */}
            {eventTypeSelected.description.toLocaleLowerCase() === "factura"
              ? <Grid
                  container
                  item
                  className="formEvent"
                  columnSpacing={1}
                  rowSpacing={0}
                  xs={12}
                >
                  {/* RFC emiter */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">RFC Emisor:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="rfcEmiter"
                      autoComplete="rfcEmiter"
                      size="small"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      {...register("rfcEmiter", {
                        required: "El RFC del emisor es obligatorio.",
                      })}
                      error={!!errors?.rfcEmiter}
                      helperText={
                        errors?.rfcEmiter ? errors.rfcEmiter.message : null
                      }
                    />
                  </Grid>

                  {/* Name/BusinessName emiter */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Nombre o Razón Social Emisor:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="nameEmiter"
                      autoComplete="nameEmiter"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("nameEmiter", {
                        required:
                          "El Nombre o razon social del emisor es obligatorio.",
                      })}
                      error={!!errors?.nameEmiter}
                      helperText={
                        errors?.nameEmiter ? errors.nameEmiter.message : null
                      }
                    />
                  </Grid>

                  {/* RFC Receptor */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">RFC Receptor:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="rfcReceptor"
                      autoComplete="rfcReceptor"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("rfcReceptor", {
                        required: "El RFC del receptor es obligatorio.",
                      })}
                      error={!!errors?.rfcReceptor}
                      helperText={
                        errors?.rfcReceptor ? errors.rfcReceptor.message : null
                      }
                    />
                  </Grid>

                  {/* Name/BusinessName receptor */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Nombre o Razón Social Receptor :
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="nameReceptor"
                      autoComplete="NameReceptor"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("nameReceptor", {
                        required: "El Nombre o razon social del receptor es obligatorio.",
                      })}
                      error={!!errors?.nameReceptor}
                      helperText={errors?.nameReceptor ? errors.nameReceptor.message : null}
                    />
                  </Grid>

                  {/* Fiscal Folio */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Folio Fiscal:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="fiscalFolio"
                      autoComplete="fiscalFolio"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("fiscalFolio", {
                        required: "El folio fiscal es obligatorio.",
                      })}
                      error={!!errors?.fiscalFolio}
                      helperText={
                        errors?.fiscalFolio ? errors.fiscalFolio.message : null
                      }
                    />
                  </Grid>

                  {/* Expedition date */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Fecha de expedición:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="expeditionDate"
                      type='datetime-local'
                      autoComplete="expeditionDate"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("expeditionDate", {
                        required: "La fecha de expedición es obligatoria.",
                      })}
                      error={!!errors?.expeditionDate}
                      helperText={
                        errors?.expeditionDate
                          ? errors.expeditionDate.message
                          : null
                      }
                    />
                  </Grid>

                  {/* Certification Date from SAT */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Fecha de certificación SAT:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="certificationDateSAT"
                      autoComplete="certificationDateSAT"                      
                      type='datetime-local'
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("certificationDateSAT", {
                        required:
                          "La fehca de certificación del SAT es obligatoria.",
                      })}
                      error={!!errors?.certificationDateSAT}
                      helperText={
                        errors?.certificationDateSAT
                          ? errors.certificationDateSAT.message
                          : null
                      }
                    />
                  </Grid>

                  {/* PAC who certified */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">PAC: que certificó:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="pacCertified"
                      autoComplete="pacCertified"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("pacCertified", {
                        required: "El PAC que certificó es obligatorio.",
                      })}
                      error={!!errors?.pacCertified}
                      helperText={
                        errors?.pacCertified
                          ? errors.pacCertified.message
                          : null
                      }
                    />
                  </Grid>

                  {/* Total CFFI */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Total CFDI:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="totalCFDI"
                      autoComplete="totalCFDI"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("totalCFDI", {
                        required: "El total CFDI es obligatorio.",
                      })}
                      error={!!errors?.totalCFDI}
                      helperText={
                        errors?.totalCFDI ? errors.totalCFDI.message : null
                      }
                    />
                  </Grid>

                  {/* Comprobant Effect */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Efecto del comprobante:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="comprobantEffect"
                      autoComplete="comprobantEffect"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("comprobantEffect", {
                        required: "El efecto del comprobante es obligatorio.",
                      })}
                      error={!!errors?.comprobantEffect}
                      helperText={
                        errors?.comprobantEffect
                          ? errors.comprobantEffect.message
                          : null
                      }
                    />
                  </Grid>

                  {/* Status CFDI */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Estado del CFDI:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="statusCFDI"
                      autoComplete="statusCFDI"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("statusCFDI", {
                        required: "El estado del CFDI es obligatorio.",
                      })}
                      error={!!errors?.statusCFDI}
                      helperText={
                        errors?.statusCFDI ? errors.statusCFDI.message : null
                      }
                    />
                  </Grid>

                  {/* Status cancelation */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Estatus de Cancelación:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="statusCancelation"
                      autoComplete="statusCancelation"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("statusCancelation", {
                        required: "El estatus de cancelación es obligatorio.",
                      })}
                      error={!!errors?.statusCancelation}
                      helperText={
                        errors?.statusCancelation
                          ? errors.statusCancelation.message
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              : null
            }

            {/* Was selected fines */}
            {eventTypeSelected.description.toLocaleLowerCase() === "infracciones"
              ? <Grid
                  container
                  item
                  className="formEvent"
                  columnSpacing={1}
                  rowSpacing={0}
                  xs={12}
                >
                  {/* Consultation date */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Fecha de Consulta:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="consultationDate"
                      autoComplete="consultationDate"
                      size="small"
                      type='date'
                      style={{ marginTop: 5, marginBottom: 5 }}
                      {...register("consultationDate", {
                        required: "La feche de consulta es obligatoria.",
                      })}
                      error={!!errors?.consultationDate}
                      helperText={
                        errors?.consultationDate ? errors.consultationDate.message : null
                      }
                    />
                  </Grid>

                  {/* Source */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Fuente:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="source"
                      autoComplete="source"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("source", {
                        required:
                          "La fuente es obligatoria.",
                      })}
                      error={!!errors?.source}
                      helperText={
                        errors?.source ? errors.source.message : null
                      }
                    />
                  </Grid>

                  {/* Have fines */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">¿Tiene multas?:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      select
                      size="small"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      value={haveFines === 1 ? 'Si' : haveFines === 2 ? 'No' : ''}
                      {...register("haveFines", {
                        required: "Debe indicarse si se tienen multas.",
                        onChange: ((e) => {
                          if(e.target.value.toLocaleLowerCase() === 'si') {
                            setHaveFines(1)
                          } else if(e.target.value.toLocaleLowerCase() === 'no') {
                            setHaveFines(2)
                          }
                        })
                      })}
                      error={!!errors?.haveFines}
                      helperText={!!errors?.haveFines ? errors.haveFines.message : null}
                    >
                      <MenuItem value={'Si'}>
                        Sí
                      </MenuItem>
                      <MenuItem value={'No'}>
                        No
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              : null
            }

            {/* Have or not fines */}
            {haveFines === 1 && eventTypeSelected.description.toLocaleLowerCase() === "infracciones"
              ? <Grid
                  container
                  item
                  className="formEvent"
                  columnSpacing={1}
                  rowSpacing={0}
                  xs={12}
                >
                  {/* Quantity */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">
                      Cantidad:
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="quantity"
                      autoComplete="quantity"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("quantity", {
                        required: "El RFC del receptor es obligatorio.",
                      })}
                      error={!!errors?.quantity}
                      helperText={
                        errors?.quantity ? errors.quantity.message : null
                      }
                    />
                  </Grid>

                  {/* Fine amount */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Monto Multa:</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="fineAmount"
                      autoComplete="fineAmount"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("fineAmount", {
                        required: "El monto de la multa es obligatorio.",
                      })}
                      error={!!errors?.fineAmount}
                      helperText={
                        errors?.fineAmount ? errors.fineAmount.message : null
                      }
                    />
                  </Grid>

                  {/* Fine amount */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography variant="body1">Donde pagar (URL):</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="urlToPay"
                      autoComplete="urlToPay"
                      style={{ marginTop: 5, marginBottom: 5 }}
                      size="small"
                      {...register("urlToPay", {
                        required: "La URL para poder realizar el pago es obligatoria.",
                      })}
                      error={!!errors?.urlToPay}
                      helperText={
                        errors?.urlToPay ? errors.urlToPay.message : null
                      }
                    />
                  </Grid>
                </Grid>
              : null
            }

            {/* Button confirm event */}
            {eventTypeSelected.id !== 0 && <Grid
              container
              item
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className="buttonsModalEvent"
              xs={12}
            >
              <Button
                autoFocus
                type="submit"
                size="small"
                variant="contained"
                //onClick={handleCloseModalAceptEvent}
              >
                Confirmar Evento
              </Button>
            </Grid>}
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalEvent;
