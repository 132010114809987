// Import Router
import Router from './Router'

// Import styles CSS
import './assets/css/App.css'

function App() {
  return (
    <Router />
  );
}

export default App;