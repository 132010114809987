import React from 'react';

// Material UI components
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem
} from '@mui/material'

//React Hook Form imports
import { useForm } from "react-hook-form"


const ModalErrorUser = ({ openError, handleCloseModalError, handleCloseModalErrorAcept, data }) => {
    return (
        <Dialog
            open={openError}
            onClose={handleCloseModalError}        
            hideBackdrop ={true}
            className='modalClient'
            fullWidth
            maxWidth='md'
        >
            <DialogContent className='modalContent'>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <Typography
                        variant='h3'
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {data.message}
                    </Typography>

                    {/* Button actions */}
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className='buttonsModal'
                        xs={12}
                    >

                        <Button 
                            autoFocus
                            size="small"
                            variant='contained'
                            onClick={handleCloseModalErrorAcept} 
                        >
                            Notificar
                        </Button>
                        <Button 
                            size="small"
                            variant='contained'
                            onClick={handleCloseModalError}
                        >
                            Regresar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ModalErrorUser