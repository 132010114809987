import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  '../components/errorHandler'

/* Service to Login
@params
    * email: string
    * password: string
*/
export const Login = async (data) => {
    try {
        const body = {
            email: data.email,
            password: data.password
        }

        const response = await fetch(`${Constants.API.BASE_URL}/auth/monica/V1/login`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}


/* Service to get Me
@params
    * none
@headers
    * token: string
*/
export const getMe = async () => {
    const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

    try {
        const response = await fetch(`${Constants.API.BASE_URL}/auth/monica/V1/me`, {
            method: 'GET',
            headers: {
                'Authorization': jwtToken,
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}

/* Service to Logout 
@params
    * none
*/
export const Logout = async () => {
    document.cookie = `jwtToken=; expires=${Date.now()}; path=/`;
    document.cookie = `jwtRefresh=; expires=${Date.now()}; path=/`;

    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = "jwtToken=; expires=" + date.toUTCString() + "; path=/";
    document.cookie = "jwtRefresh=; expires=" + date.toUTCString() + "; path=/";

    localStorage.clear();
    //console.log('logged out')
}


/* Service to Refresh Token
@params
    * none
@headers
    * token: string
    * refreshToken: string
*/
export const refreshToken = async () => {
    
}