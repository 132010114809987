import fetch from 'node-fetch'
import * as Constants from '../environment'
import errorHandler from  './../components/errorHandler'

/* Service to get Request
@params
    * none
@headers
    * token: string
*/
export const getRequest = async () => {
    try {
        const response = await fetch(`${Constants.API.BASE_URL}/customers/V1/getCustomers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success) {
            return jsonResponse
        } else {
            return errorHandler(jsonResponse.error.code, jsonResponse.error.message)
        }
    } catch (error) {
        return errorHandler(500, 'Error al conectar con el servidor')
    }
}